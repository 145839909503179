<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t('message.add_new_service') }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="primary" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save') }}</el-button>
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="updatingService(true)">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="close()">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="services_block">
      <el-row class="p20" v-loading="loadingData">
        <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
          <el-select v-model="patientServiceId" filterable :placeholder="$t('message.start_input_find_service')" class="autoInput" clearable>
            <template v-for="serviceParent in servicesSelected">
              <el-option-group
                  v-if="serviceParent.children.length > 0"
                  :key="serviceParent.id"
                  :label="serviceParent.name">
                <el-option
                    v-for="service in getChildrens(serviceParent.children)"
                    :key="service.id"
                    :label="service.name"
                    :value="service.id">
                </el-option>
              </el-option-group>
              <el-option-group
                  v-else-if="serviceParent.is_service == true && serviceParent.parent_id == null"
                  :key="serviceParent.id">
                <el-option
                    :label="serviceParent.name"
                    :value="serviceParent.id">
                </el-option>
              </el-option-group>
            </template>
          </el-select>
        </el-col>
        <!-- end col -->

        <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
          <el-button style="font-size:14px!important;padding:12px!important;" @click="addServicex">{{ $t('message.add') }}</el-button>
        </el-col>
        <!-- end col -->

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <table class="table table-striped table-bordered mt-4">
            <thead>
            <tr>
              <th>{{ $t('message.pp') }}</th>
              <th class="wi-50">{{ $t('message.name') }}</th>
              <th class="wi-7">{{ $t('message.quantity_short') }}</th>
              <th class="wi-7">{{ $t('message.price2') }}</th>
              <th class="wi-7">{{ $t('message.discount_percent') }}</th>
              <th class="wi-7">{{ $t('message.discount_price') }}</th>
              <th class="wi-7">{{ $t('message.amount') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{  1 }}</td>
              <td>
                {{ form.name }}
              </td>
              <td>
                <el-input type="number"  id="usr"  step="any" @input="editRow(form,$event,'count')" v-model="form.count"  :min="1"></el-input>
              </td>
              <td>
                {{ form.price | formatMoney }}
              </td>
              <td>
                <el-input type="number" step="any" @input="editRow(form,$event,'discount')" v-model="form.discount"  :min="0" :max="100"></el-input>
              </td>
              <td>
                {{ Math.round(form.discount * form.price/100) | formatMoney }}
              </td>
              <td>
                {{ Math.round(form.count * (form.price - ((form.price * form.discount) / 100))  ) | formatMoney }}
              </td>
            </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import order from '@/utils/mixins/order';
import form from '@/utils/mixins/form';
import drawerChild from '@/utils/mixins/drawer-child';

export default {
  mixins: [form, order, drawerChild],
  props: ['currentPatient', 'selected'],
  data() {
    return {
      reopenUpdate: false,
      loadingButton:false,
      loadingData: false,
    };
  },

  computed:{
    ...mapGetters({
      rules: 'hospitalizationServices/rules',
      modell: 'hospitalizationServices/modell',
      columns: 'hospitalizationServices/columns',
      getId: 'patients/activeCode'
    })
  },
  methods: {
    ...mapActions({
      editPatient: 'patients/show',
      editService: 'services/show',
      save: 'hospitalizationServices/store',
      editModel: 'hospitalizationServices/show',
      update: 'hospitalizationServices/update',
      assignService: 'hospitalizationServices/assign',
      saveList: 'services/setlist',
    }),
    addServicex() {
      let vm = this;
      let service = this.servicesSelected.find(function(item) {
        return item.id == vm.patientServiceId;
      })
      if (service) {
        this.assignService(JSON.parse(JSON.stringify(service)))
            .then(res => {
              this.form = JSON.parse( JSON.stringify(this.modell));
            }).catch(err => {});
      }
    },
    afterOpened(){
      if (this.selected) {
        this.loadingData = true;
        this.editModel(this.selected.id)
            .then(async (res) => {
              this.loadingData = false;
              this.form = JSON.parse( JSON.stringify(this.modell));
            })
            .catch(err => {
              this.loadingData = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              })
            });
      }
    },
    afterClosed(){
      this.empty()
    },
    setNewOrderData(){
      this.form = JSON.parse( JSON.stringify( this.modell ));
    },
    updatingService(close = true){
      this.loadingButton = true;
      this.form.hospitalization_service_id = this.selected.id;
      this.update(this.form)
          .then(res => {
            this.$notify({
              title: 'Успешно',
              type: "success",
              offset: 130,
              message: res.message
            });
            this.parent().listChanged()
            if (close == true) {
              this.close();
            }
            this.loadingButton = false;
          })
          .catch(err => {
            this.ifValidationError(err);
          });
    },
    closeForm(){
      this.old_order = {};
      this.old_save = false;
      this.empty()
      this.$emit('c-close',{reload: false, drawer: this.drawer});
    },
    resetForm(){
      this.setNewOrderData();
      this.user = {};
      this.noUser();
      this.emptySearch();
      this.emptyList();
      this.search = '';
      this.latestServices = [];
    },
    empty(){
      this.activeTab = 'first';
      this.emptyList();
      this.emptySearch();
      this.noUser();
      this.user = null;
      this.patientServiceId = '';
      this.search = '';
      this.latestServices = [];
    }
  }
}
</script>
<style lang="scss" >
.services_block {
  padding: 20px;
}
.select_grupp {
  display: flex;
  .el-select {
    width: 600px;
    margin-right: 20px;
  }
}
.topSarche {
  display: flex;
  justify-content: space-between;
}
.wt5 {
  width: 4%;
}
.wt10 {
  width: 12%;
}
</style>